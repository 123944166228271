import {
    AccountNode,
    ContactNode,
    EcnNode,
    ProductNode,
    QuoteNode,
    SaleNode,
    SeederNode,
    SupplyOrderNode,
    WarrantyClaimNode,
    QuotedProductFormInput,
    GetProductsStocksQuery,
    QuotedProductFragment,
    SupplyOrderDetailFragment,
    GetProductsEffectiveDatesQuery,
} from 'generated/graphql'

export interface Hash<T> {
    [key: string]: T
}

export const getObjectKeys = <T extends object>(value: T) => Object.keys(value) as (keyof T)[]
export const getObjectEntries = <T extends object>(value: T) => Object.entries(value) as [keyof T, T[keyof T]][]

export const getHash = <T extends object>(items: T[], key: Only<T, string>) => {
    return items.reduce<Hash<T>>((hash: Hash<T>, item: T) => {
        const hashKey = item[key]
        if (typeof hashKey !== 'string')
            throw new Error('This error should never happend "Only<T, string>" should allow keys that return strings')
        hash[hashKey as string] = item
        return hash
    }, {})
}

export const encodeToB64 = (object: any) => {
    return Buffer.from(JSON.stringify(object)).toString('base64')
}

export const decodeFromB64 = (value: string) => {
    return JSON.parse(Buffer.from(value, 'base64').toString())
}

export const openData = ({data, filename, contentType}: {data: string; filename: string; contentType: string}) => {
    const link = document.createElement('a')
    link.href = `data:${contentType};filename=${filename};base64,${data}`
    link.setAttribute('download', filename)
    link.click()
}

export const notEmpty = <T>(value: T | undefined | null): value is T => value !== undefined && value !== null

export const isUrlPdf = (url: string) => {
    return url.split('?').shift()?.endsWith('.pdf')
}

export const notUndefined = <T>(value: T | undefined): value is T => value !== undefined

export function toGlobalId(typename: Typenames, uuid: string) {
    if (typeof window === undefined) throw new Error('Only client function')
    if (!checkIfValidUUID(uuid)) {
        return uuid
    }
    return btoa(`${typename}:${uuid}`)
}

export const productHasStock = (
    product: QuotedProductFormInput | QuotedProductFragment,
    stockData?: GetProductsStocksQuery,
) => {
    const stock = stockData?.productsStocks?.stocks.find(stock => stock.externalId === product.externalId)
    return stock ? stock.availableQuantity >= product.quantity : true
}

function checkIfValidUUID(str: string) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

    return regexExp.test(str)
}

type Typenames = NonNullable<
    | ProductNode['__typename']
    | AccountNode['__typename']
    | QuoteNode['__typename']
    | SupplyOrderNode['__typename']
    | SaleNode['__typename']
    | ContactNode['__typename']
    | WarrantyClaimNode['__typename']
    | SeederNode['__typename']
    | EcnNode['__typename']
>

export const unwrapEdges = <
    T extends undefined | {edges: Array<{node?: unknown}>},
    TNode = NonNullable<NonNullable<T>['edges'][number]['node']>,
>(
    arr?: T,
): TNode[] => {
    if (!arr) return []
    const result = arr.edges.map(edge => edge.node).filter(notEmpty)
    return result as TNode[]
}

export function getShipmentQuantityForQuoteProduct(
    supplyOrder: SupplyOrderDetailFragment | null | undefined,
    quoteProductId: string,
) {
    return supplyOrder?.shipments
        .flatMap(shipment => shipment.productsShipped)
        .filter(product => product.orderedProduct.quotedProduct.id === quoteProductId)
        .reduce((acc, curr) => {
            return acc + curr.quantity
        }, 0)
}

export const productIsEffective = (
    product: {externalId: string},
    effectiveDatesData?: GetProductsEffectiveDatesQuery,
) => {
    if (!effectiveDatesData?.products?.edges) return true
    const productData = effectiveDatesData.products.edges.find(edge => edge.node?.externalId === product.externalId)
    return productData?.node?.isEffective ?? true
}
